<template>
  <div id="header" style="transition: all ease 1s">
    <button id="burgerss" @change="ChangeCurency()">
        <!-- <p class="CurencyB">$</p> -->
        <select name="Curency" id="burgerss"  v-model="selected">
            <!-- <option disabled value="">Currency</option> -->
            <option value="USD">USD</option>
            <option value="PKR">PKR</option>
        </select>
    </button>
    
    <button id="burger" @click="openNav()" v-bind:class="{'is-active': active}"> <span></span> <span></span> <span></span> Open Menu </button>
    <button id="burgers" @click="openCart()">
        <img src="../svg/icon-cart.png" alt="">
    </button>
    <div id="menu" style="transition: all ease 1s">
        <div class="m-logo">
            <h1>Home</h1>
            <a href="#/page/home"><img src="@/assets/logo.png" class="logo-header"></a>
        </div>
        <div class="m-menu">
            <ul v-for="(itm,index) in data" :key="index">
                <li v-for="(itm,index) in itm.child" :key="index">
                    <a aria-current="page" v-if="itm.link == 'myaccount' && !userInfo.id" @click="openSigninPop()">{{itm.title}}</a>
                    <a :href="'#/' + itm.link" aria-current="page" v-else>{{itm.title}}</a>
                    <ul class="sub-menu" v-if="itm.child">
                        <li v-for="(itm,index) in itm.child" :key="index">
                            <a :href="'#/' + itm.link" aria-current="page">{{itm.title}}</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
    <cart id="cartContainer"/>
    <navgation/>
    <Signin id="signinContainer"/>
    <Signup id="signupContainer"/>
  </div>
</template>

<script>
import axios from 'axios'
export default {
    components:{
        navgation: () => import('./Navigation.vue'),
        Cart: () => import('./Cart.vue'),
        Signin: () => import('./Signin.vue'),
        Signup: () => import('./Signup.vue'),
    },
    data(){
        return{
            active: false,
            selected: '',
            data: {},
            userInfo: false
        }
    },
    watch: {
        "$route.params": {
            handler(newValue, preValue) {
                this.userInfo = this.$store.state.customer
                if( document.getElementById('nav')){
                    document.getElementById('nav').classList.remove('openNavigation') 
                }
                this.active = false
            },
            immediate: true
            }
    },
    created(){
        if(localStorage.getItem('currency')){
            this.selected = JSON.parse(localStorage.getItem('currency')) 
        }else{
            localStorage.setItem('currency', JSON.stringify('PKR'))
            this.selected = 'PKR'
        }
        this.userInfo = this.$store.state.customer
        this.init()
    },
    mounted(){
         window.addEventListener('scroll', this.handleScroll);
    },
    methods:{
        handleScroll(){
            if (window.scrollY > 100) {
                if( document.getElementById('menu')){
                    document.getElementById('menu').classList.add("displayNone");
                }
            } else {
                if( document.getElementById('menu')){
                    document.getElementById('menu').classList.remove("displayNone");
                }
            }
        },
        openNav(){
            if(this.active == false){
                if(document.getElementById('nav')){
                    document.getElementById('nav').classList.add('openNavigation')
                }
                this.active = true
            }else{
                if(document.getElementById('nav')){
                    document.getElementById('nav').classList.remove('openNavigation') 
                }
                this.active = false
            }
        },
        openCart(){
            if(document.getElementById('cartContainer')){
                document.getElementById('cartContainer').classList.add('openCart'); 
            }
        },
        init(){
            axios
            .post(
                this.$store.state.api + "navigation/_search",
                {
                    from: 0,
                    size: 5000,
                    query: {
                    bool: {
                    must: [
                        {
                            match_phrase: {
                                event: this.$store.state.event
                            }
                        },
                        {
                            match_phrase: {
                                heading: "header"
                            }
                        }
                    ]
                }
              }
            }
            ).then(response => {
                this.data = {}
                var res = response.data.hits.hits
                for(var ind in res){
                    this.data[ind] = res[ind]._source
                }
            })
        },
        openSigninPop(){
            if(document.getElementById('signinContainer')){
                document.getElementById('signinContainer').classList.add('showSigninContainer'); 
            }
        },
        checkUser(){
            if(this.$store.state.customer.email){
                this.$router.push({path:'/page/sign_up'})
            }else{
                this.$router.push({path:'/page/signin'})
            }
        },
        logout(){
            localStorage.removeItem('users')
            this.$store.dispatch("Store");
            this.userInfo = {}
            this.$router.push({name:'splash'})
            this.$forceUpdate();
            location.reload()
        },
        ChangeCurency(){
            this.$store.state.currency = this.selected
            localStorage.setItem('currency', JSON.stringify(this.selected))
            location.reload()
        }
   }
}
</script>

<style>
.m-logo a {
    display: block;
    overflow: initial;
    text-indent: -999vw;
    height: auto;
}
.displayNone{
    transition: all ease .5s !important;
    opacity: 0;
}
.openNavigation{
    top: 0 !important;
    /* transform: translate(0px, 0px) !important; */
    /* transition: 1s all ease !important; */
}
.openNavigation .n-head{
    transform: translate(-40px, -40px) !important;
    transition: 1.8s all ease !important;
}
.openNavigation .n-figure{
    transform: translate(0px, 0px) !important;
    transition: 1s all ease !important;
}
.openNavigation .n-menu{
    transform: translate(0px, 0px) !important;
    transition: 1.5s all ease !important;
}
.openNavigation .n-background{
    transition: 1.5s all ease !important;
    opacity: 0.8 !important;
}
#burgerss{
    font-weight: 500;
    letter-spacing: 2px;
    font-size: 0.85vw;
    font-family: 'Aktiv Grotesk';
    right: calc(100vw / 12.5 * 1);
    top: 3.45vw;
    width: 2.2vw;
    height: 2vw;
    z-index: 95;
    padding: 2px;
    display: block;
    cursor: pointer;
    position: fixed;
    border-radius: 5px;
    padding: 0 3spx;
    background: #ffffff00;
}
.CurencyB{
    position: absolute;
    right: 5px;
    top: 4px;
}
#burgerss select {
    -webkit-appearance: none; 
}
#burgers{
    right: calc(100vw / 20);
    top: 3.2vw;
    width: 2vw;
    height: 2.25vw;
    z-index: 95;
    font-size: 0px;
    display: block;
    cursor: pointer;
    position: fixed;
    right: 2%;
}
#burgers .fa{
    font-size: 24px;
}
.m-menu .sub-menu{
    flex-direction: column;
    display: none;
    animation: fadein .5s ease-in-out;
    /* margin-top: 10px; */
    background: #fff;
    padding: 0 10px 10px;
    min-width: 150px;
    position: absolute;
    border-radius: 5px;
    top: 22px;
    box-shadow: 0 2px 2px #d5d5d5;
    width: 63px

}
@keyframes fadein{
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}
.m-menu .sub-menu li{
    padding-top: 10px;
    margin: 0;
}
.m-menu li:hover .sub-menu{
    display: block;
    /* margin-right: 0 !important; */
}
.m-menu li:nth-child(2):hover{
    /* margin-right: 0 !important; */
}
.m-logo .logo-header{
    width: 70%;
}
.m-menu ul {
    display: flex;
    line-height: 1.25vw;
    margin-right: 40px;
}
@media only screen and (max-width: 580px){
    #burger {
        top: calc(100vw / 12 * 1);
        right: calc(100vw / 6 * 1);
        width: calc(100vw / 12 * 1);
        height: 4.167vw;
    }
    #burgers{
        width: 6.188vw;
        height: 1.25vw;
        -webkit-logical-height: 6.25vw;
        z-index: 95;
        font-size: 0px;
        display: block;
        cursor: pointer;
        position: fixed;
    }
    #burgerss{
        right: 29.33333vw;
        top: 7.33vw;
        width: 10.5vw;
        height: 6vw;
        font-size: 12px;
        display: block;
    }
    #burgers{
        top: calc(100vw / 14 * 1);
        right: calc(100vw / 16 * 1);
    }
    .m-logo .logo-header {
        margin-bottom: 99px;
        transform: translate(-35px, -30px);
        width: 60%;
    }
    .m-logo .logo-header {
        margin-bottom: 99px;
        transform: translate(-35px, -30px);
        width: 60%;
    }
}
</style>